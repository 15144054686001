<template>
    <div>
        <b-modal 
        :id="$route.name + 'CreateModal'" 
        title="Добавить надбавку" 
        size="s" 
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true" 
        @show="clearData"
        >
            <b-row>
                <b-col cols="12">
                    <label for="">Доп.параметры: <i class="color">*</i></label>
                    <b-form-select
                    size="sm"
                    v-model="allowanceCreate.pattern_allowance_id"
                    :options="allowanceData.patterns"
                    value-field="id"
                    text-field="name"
                    ></b-form-select>
                </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">Цена надбавки</label>
                <div style="column-count: 2; margin-top: 20px;">
                  <label>Цена от:
                    <b-form-input v-model="prices.price" type="number"></b-form-input>
                  </label>
                  <label>Количество:
                    <b-form-input v-model="prices.count" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_active = false" v-for="(price, i) in allowanceCreate.prices" :key="i">
                        {{ `От ${price.price} : ${price.count}` }}
                      </div> 
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!prices.price > 0 || !prices.count > 0" @click.prevent="getPush">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_active" @click="deletePrice">Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>  
            <b-row>
                <b-col class="mt-1 border">
                    <label>Тип стоимости</label>
                    <b-form-group style="column-count: 1;">
                        <b-form-radio-group
                        v-model="allowanceCreate.type"
                        :options="allowanceData.types"
                        value-field="key"
                        text-field="value"
                        plain
                        ></b-form-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-1 border">
                    <label>Тип заказа</label>
                    <b-form-group style="column-count: 3;">
                    <b-form-checkbox  
                    v-model="allowanceCreate.order_types"  
                    v-for="(option, i) in allowanceData.order_types"
                    :key="i"
                    :value="option.id"
                    >
                    {{ option.name }}
                    </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group class="mt-1">
                    <b-form-checkbox v-model="allowanceCreate.addr_type_relation">Только для связанных типох адресов</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                    <b-form-checkbox v-model="allowanceCreate.discount_show" >Только для отображения скидки</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                    <b-form-checkbox v-model="allowanceCreate.is_min_price" >Использовать как минимальную стоимость</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                    <b-form-checkbox v-model="allowanceCreate.add_min_price" >Включается в минимальную стоимость</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                    <b-form-checkbox v-model="allowanceCreate.is_require" >Одна из обязательных надбавок</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                    <b-form-checkbox v-model="allowanceCreate.require_parameter" >Обязательный параметр</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                    <b-form-checkbox v-model="allowanceCreate.replace_base_price_order" >Заменить базовый расчёт цены на значение данного параметра</b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createAllowance">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="clearData">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import convertSendDataJson from '@/@core/utils/convertSendData'
export default {
    props: ['allowanceData'],
    component: {
        ToastificationContent,
    },
    data(){
        return {
            index: null,
            is_active: true,
            prices: {
                price: null,
                count: null,
            },
            allowanceCreate: {
                type: null,
                prices: [],
                order_types: [],
                pattern_allowance_id: null,
                addr_type_relation: null,
                discount_show: null,
                is_min_price: null,
                add_min_price: null,
                is_require: null,
                require_parameter: null,
                replace_base_price_order: null,
            },
            submitButtonDisabled: false,
        }
    },
    methods:{
        createAllowance(){
            this.allowanceCreate.addr_type_relation = this.allowanceCreate.addr_type_relation ? 1 : 0
            this.allowanceCreate.discount_show = this.allowanceCreate.discount_show ? 1 : 0
            this.allowanceCreate.is_min_price = this.allowanceCreate.is_min_price ? 1 : 0
            this.allowanceCreate.add_min_price = this.allowanceCreate.add_min_price ? 1 : 0
            this.allowanceCreate.is_require = this.allowanceCreate.is_require ? 1 : 0
            this.allowanceCreate.require_parameter = this.allowanceCreate.require_parameter ? 1 : 0
            this.allowanceCreate.replace_base_price_order = this.allowanceCreate.replace_base_price_order ? 1 : 0
            let data = JSON.parse(JSON.stringify(this.allowanceCreate))
            convertSendDataJson(data.prices)
            this.submitButtonDisabled = true;
            this.$http
                .post('/v2/allowances/', data,)
                .then(res=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: res.data.message,
                        },
                    })
                    this.$emit('refresh')
                    this.clearData()
                }).catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        getPush() {
            this.allowanceCreate.prices.push(this.prices);
            this.prices = {
                price: null,
                count: null,
            }
        },
        getid(i) {
            this.index = i;
        },
        deletePrice() {
            this.allowanceCreate.prices.splice(this.index, 1)
            this.index = null,
            this.is_active = true
        },
        clearData(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.allowanceCreate = {
                type: null,
                prices: [],
                order_types: [],
                pattern_allowance_id: null,
                addr_type_relation: null,
                discount_show: null,
                is_min_price: null,
                add_min_price: null,
                is_require: null,
                require_parameter: null,
                replace_base_price_order: null,
            }
        },
    }
}
</script>

<style scoped>
.status {
    display: flex;
    flex-direction: column;
}
.color {
    color: red;
}
</style>