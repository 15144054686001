<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-allowance :allowances="allowances" @editForm="editForm" @refresh="refresh" @timerAfto="timerAfto"/>
            <modal-allowance @refresh="refresh" :allowanceData="allowanceData"/>
            <modal-allowance-edit :id="id" @refresh="refresh" :allowanceData="allowanceData"/>
        </div>
            <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableAllowance from '@/views/component/Table/tableAllowance.vue'
import modalAllowance from '@/views/component/Modal/ModalAllowance/ModalAllowance.vue'
import ModalAllowanceEdit from '@/views/component/Modal/ModalAllowance/ModalAllowanceEdit.vue'
export default {
    components: {
        tableAllowance,
        modalAllowance,
        ModalAllowanceEdit,
    },
    data(){
        return {
            allowances: [],
            allowanceData: [],
            showPreloader: false,
            id: undefined,
            timer: null,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '10px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '20px'}},
                { key: 'prices', label: 'Цена', sortable: true, thStyle: {width: '150px'}},
                { key: 'user_login', label: 'ID логин', sortable: true, thStyle: {width: '150px'}},
                { key: 'pattern_allowance_name', label: 'ID шаблона', sortable: true, thStyle: {width: '150px'}},
                { key: 'addr_type_relation', label: 'Добавление типа реляции', sortable: true, thStyle: {width: '150px'}},
                { key: 'discount_show', label: 'Скидка', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_min_price', label: 'Минимальная цена', sortable: true, thStyle: {width: '150px'}},
                { key: 'add_min_price', label: 'Добавить минимальную цену', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_require', label: 'Требуется', sortable: true, thStyle: {width: '150px'}},
                { key: 'require_parameter', label: 'Требуемый параметр', sortable: true, thStyle: {width: '150px'}},
                { key: 'replace_base_price_order', label: 'Замена основной цены заказ', sortable: true, thStyle: {width: '150px'}},
      ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
        // this.getDataAllowance()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('allowanceEdit')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get(`${this.$route.name}`)
                .then(res => {
                    this.allowances = res.data
                    this.$store.commit('pageData/setdataCount', this.allowances.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                }).catch(err => {})
        },
        getDataAllowance() {
            this.$http
                .get(`${this.$route.name}/data`)
                .then(res => {
                    this.allowanceData = res.data
                })
        },
        sendToParent(tableData){
            this.allowances = tableData
            this.$store.commit('pageData/setdataCount', this.allowances.length)
        },
        timerAfto(is_active) {
            is_active ? (this.timer = setInterval(() => this.refreshIs_Active(), 30000)) : clearInterval(this.timer);
        },
        refreshIs_Active() {
            this.$http
            .get(`${this.$route.name}`)
            .then(res => {
                this.allowances = res.data
            })
        },
        openFilter(){
                let allRoutes = this.$store.state.draggableTab.tabs
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                let arrayRoutes = []
                allRoutes.forEach(element => {
                    arrayRoutes.push(element.path)
                });
                if (arrayRoutes.includes(this.$route.path) == true) {
                    this.showPreloader = true
                    this.$http
                        .get(`v2/allowances/`, {params: param}).then(res => {
                            this.allowances = res.data
                            this.$store.commit('pageData/setdataCount', this.allowances.length)
                            this.showPreloader = false
                            this.$store.commit('REFRESH_DATA', false)
                        })
                }else if(arrayRoutes.includes(this.$route.path) == false){
                    this.$bvModal.show(this.$route.name + 'filter')
                }
            },
        },
        computed: {
            fetchingNewData(){
                return this.$store.state.refresh.fetchingNewData
            }
        },
        watch: {
            fetchingNewData(val){
                if (val){
                    this.$http
                    .get(`${this.$route.name}`)
                    .then(res =>{
                        this.allowances = res.data
                        this.$store.commit('pageData/setdataCount', this.allowances.length)
                        this.$store.commit('REFRESH_DATA', false)
                    })
                    }
                }
        }
}
</script>